import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout pageTitle="404: Not Found">
    <SEO title="404: Not found" />
    <p>The sun has gone down and it's too dark to skate.</p>
    <Link className="btn btn-danger text-uppercase"  to={'/'}>Go Home</Link>
    
  </Layout>
)

export default NotFoundPage
